import {
  Snackbar,
  SnackbarProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

const displayName = 'NotificationProvider';

type NotificationProps = Omit<
  SnackbarProps,
  'anchorOrigin' | 'autoHideDuration' | 'onClose' | 'open'
>;

type NotificationContextType = {
  hideNotification: () => void;
  showNotification: (props: NotificationProps) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined,
);

export const useNotification = (): NotificationContextType => {
  const notificationTuple = useContext(NotificationContext);

  if (!notificationTuple) {
    throw new Error('No <NotificationProvider />');
  }

  return notificationTuple;
};

const NotificationProvider: FC = ({ children }) => {
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [snackbarProps, setSnackbarProps] = useState<
    NotificationProps | undefined
  >();

  const lastRef = useRef(snackbarProps);

  if (snackbarProps) {
    lastRef.current = snackbarProps;
  }

  const hideNotification = useCallback(() => setSnackbarProps(undefined), []);

  return (
    <NotificationContext.Provider
      value={{
        hideNotification,
        showNotification: setSnackbarProps,
      }}
    >
      {children}
      <Snackbar
        {...lastRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: smUp ? 'top' : 'bottom',
        }}
        autoHideDuration={10000}
        onClose={hideNotification}
        open={Boolean(snackbarProps)}
      />
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

NotificationProvider.displayName = displayName;

export default NotificationProvider;
