import { setContext } from '@apollo/client/link/context';

let authorization: string | undefined;

export const setAuthorization = (token?: string): void => {
  authorization = token ? `Bearer ${token}` : undefined;
};

const authLink = setContext((_, { headers }) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  headers: {
    ...headers,
    authorization,
  },
}));

export default authLink;
