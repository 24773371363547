import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';

import CenterContainer from '../CenterContainer';
import HeadTitle from '../HeadTitle';

type ExceptionProps = {
  description?: ReactNode;
  error: Error | ReactNode;
  title?: string;
};

const Exception: FC<ExceptionProps> = ({ description, error, title }) => (
  <>
    <HeadTitle>{title || 'Error'}</HeadTitle>
    <CenterContainer>
      <Typography gutterBottom variant="h5">
        {error instanceof Error ? error.toString() : error}
      </Typography>
      {description && <Typography variant="body1">{description}</Typography>}
    </CenterContainer>
  </>
);

Exception.propTypes = {
  description: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.node])
    .isRequired,
  title: PropTypes.string,
};

Exception.defaultProps = {
  description: null,
  title: undefined,
};

export default Exception;
