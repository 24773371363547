import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  ThemeOptions,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { options } from '../../theme';

const displayName = 'ThemeProvider';

type ThemeContextType = {
  setThemeOptions: Dispatch<SetStateAction<ThemeOptions>>;
  themeOptions: ThemeOptions;
};

const ThemeContext = createContext<ThemeContextType>({
  setThemeOptions: () => undefined,
  themeOptions: options,
});

export const useThemeOptions = (): ThemeContextType => useContext(ThemeContext);

const ThemeProvider: FC = ({ children }) => {
  const dark = useMediaQuery('(prefers-color-scheme: dark)');

  const mode = dark ? 'dark' : 'light';

  const [themeOptions, setThemeOptions] = useState<ThemeOptions>(() => ({
    ...options,
    palette: {
      ...options.palette,
      mode,
    },
  }));

  useEffect(() => {
    if (mode !== themeOptions.palette?.mode) {
      setThemeOptions((options) => ({
        ...options,
        palette: {
          ...options.palette,
          mode,
        },
      }));
    }
  }, [themeOptions, mode]);

  const theme = useMemo(() => createMuiTheme(themeOptions), [themeOptions]);

  return (
    <ThemeContext.Provider
      value={{
        setThemeOptions,
        themeOptions,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeProvider.displayName = displayName;

export default ThemeProvider;
