import { parseDomain, ParseResultType } from 'parse-domain';
import PropTypes from 'prop-types';
import { createContext, FC, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import Exception from '../Exception';
import Loading from '../Loading';

export const DatagonContext = createContext<string | undefined>(undefined);

export const useOrganizationUrl = (): string => {
  const origin = window.location.origin;
  const baseUrl = 'https://organizations-config.internal.swaarm.com';

  // Try to guess the environemnt based on the domain
  if (
    (origin.includes('internal') && !origin.includes('demo')) ||
    origin.includes('localhost')
  ) {
    return `${baseUrl}/staging`;
  }

  return `${baseUrl}/production`;
};

export const useDatagonUrl = (): string => {
  const datagonUrl = useContext(DatagonContext);

  if (!datagonUrl) {
    throw new Error('No datagon');
  }

  return datagonUrl;
};

const loadOrganization = async (organizationBucket: string, domain: string) => {
  const res = await fetch(
    `${organizationBucket}/${domain}.json?cache=${Date.now()}`,
  );

  return (await res.json()) as { datagonUrl: string };
};

const DatagonProvider: FC = ({ children }) => {
  const organizationUrl = useOrganizationUrl();

  const domain = useMemo(() => {
    const { hostname } = window.location;

    // Specific environment for Rokey to test farmer
    if (hostname.includes('rokey.swaarm.com')) {
      return 'rokey.theiris';
    }

    const parseResult = parseDomain(hostname);

    if (parseResult.type === ParseResultType.Listed && parseResult.domain) {
      if (
        parseResult.domain === 'swaarm-clients' &&
        parseResult.subDomains.length > 0
      ) {
        return parseResult.subDomains[parseResult.subDomains.length - 1];
      }

      return parseResult.domain;
    }

    return 'swaarm';
  }, []);

  const { data: organization, error, isLoading: loading } = useQuery<
    { datagonUrl: string },
    string
  >([organizationUrl, domain], () => loadOrganization(organizationUrl, domain));

  const datagonUrl = organization?.datagonUrl;

  if (error) {
    return (
      <Exception
        description="Thank you for being patient while we do some work on the site. Please check back soon."
        error="Maintenance"
        title="Maintenance"
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <DatagonContext.Provider value={datagonUrl}>
      {children}
    </DatagonContext.Provider>
  );
};

DatagonProvider.propTypes = {
  children: PropTypes.node,
};

DatagonProvider.displayName = 'DatagonProvider';

export default DatagonProvider;
