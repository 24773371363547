import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import type { FC } from 'react';

const displayName = 'Loading';

const useStyles = makeStyles(
  {
    loading: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  },
  { name: displayName },
);

type LoadingProps = { className?: string };

const Loading: FC<LoadingProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.loading, className)}>
      <CircularProgress />
    </div>
  );
};

Loading.displayName = displayName;

export default Loading;
