import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import AuthenticationProvider from '../AuthenticationProvider';
import ConfigProvider from '../ConfigProvider';
import DatagonProvider from '../DatagonProvider';
import Exception from '../Exception';
import GoogleAnalytics from '../GoogleAnalytics';
import GraphQLProvider from '../GraphQLProvider';
import Loading from '../Loading';
import NotificationProvider from '../NotificationProvider';
import OrganizationProvider from '../OrganizationProvider';
import ThemeProvider from '../ThemeProvider';

const displayName = 'Root';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true,
    },
  },
});

const Root = () => (
  <ThemeProvider>
    <ErrorBoundary fallback={({ error }) => <Exception error={error} />}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <Suspense fallback={<Loading />}>
              <DatagonProvider>
                <ConfigProvider>
                  <GoogleAnalytics />
                  <GraphQLProvider>
                    <OrganizationProvider>
                      <AuthenticationProvider>
                        <App />
                      </AuthenticationProvider>
                    </OrganizationProvider>
                  </GraphQLProvider>
                </ConfigProvider>
              </DatagonProvider>
            </Suspense>
          </NotificationProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </ThemeProvider>
);

Root.displayName = displayName;

export default Root;
