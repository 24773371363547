import { ThemeOptions } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

const palette = {
  primary: {
    main: blue[500],
  },
};

const typography = {
  fontFamily: 'Lato, sans-serif',
};

// https://github.com/mui-org/material-ui/blob/27471b4564eb40ff769352d73a29938d25804e45/packages/material-ui/src/styles/createTypography.js#L3
const round = (value: number) => {
  return Math.round(value * 1e5) / 1e5;
};

const { pxToRem } = createTypography(createPalette(palette), typography);

export const options: ThemeOptions = {
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: pxToRem(14),
          lineHeight: `${round(16 / 14)}em`,
          padding: '8px 16px',
        },
      },
    },

    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette,
  shape: {
    borderRadius: 2,
  },
  typography,
};
