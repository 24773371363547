/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useConfig } from '../ConfigProvider';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
window.dataLayer = window.dataLayer || [];

function gtag() {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,prefer-rest-params,@typescript-eslint/no-unsafe-member-access
  window.dataLayer.push(arguments); // analytics wants exactly arguments not args
}

// @ts-ignore
gtag('js', new Date());

let appended = false;

const GoogleAnalytics: FC = () => {
  const { analyticsId } = useConfig();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!appended && analyticsId) {
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;

      document.body.appendChild(script);

      appended = true;
    }
  }, [analyticsId]);

  useEffect(() => {
    if (analyticsId) {
      // @ts-ignore
      gtag('config', analyticsId, { page_path: pathname });
    }
  }, [analyticsId, pathname]);

  return null;
};

GoogleAnalytics.displayName = 'GoogleAnalytics';

export default GoogleAnalytics;
