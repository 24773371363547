import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import PropTypes from 'prop-types';
import { createContext, FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useDatagonUrl } from '../DatagonProvider';

const displayName = 'ConfigProvider';

type Config = {
  datagonUrl: string;
  environment: string;
  analyticsId: string;
  legacy: boolean;
};

const ConfigContext = createContext<Config | undefined>(undefined);

export const useConfig = (): Config => {
  const config = useContext(ConfigContext);
  const datagonUrl = useDatagonUrl();

  if (!config) {
    throw new Error('No config');
  }

  if (config.legacy) {
    return {
      ...config,
      datagonUrl,
    };
  }

  return config;
};

const loadConfig = async () => {
  const res = await fetch(`/config.json?cache=${CACHE}`);

  try {
    return (await res.json()) as Config;
  } catch (e) {
    throw new Error('Failed to load config');
  }
};

const ConfigProvider: FC = ({ children }) => {
  const { data: config } = useQuery<Config, string>('config', loadConfig);

  const environment = config?.environment;

  useEffect(() => {
    Sentry.init({
      dsn: 'https://c240afe82435489d9b8d92d05b085e46@sentry.io/1328769',
      enabled: environment === 'production',
      environment,
      integrations: [new TracingIntegrations.BrowserTracing()],
      release: `partner-${VERSION}`,
      tracesSampleRate: 0.1,
    });
  }, [environment]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ConfigProvider.displayName = displayName;

export default ConfigProvider;
