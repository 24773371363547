import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import PropTypes from 'prop-types';
import { FC, useEffect, useMemo } from 'react';

import { useAuthentication } from '../AuthenticationProvider';
import { useConfig } from '../ConfigProvider';

import authLink, { setAuthorization } from './authLink';
import sentryLink from './sentryLink';
import useErrorLink from './useErrorLink';

const GraphQLProvider: FC = ({ children }) => {
  const { accessToken } = useAuthentication();
  const config = useConfig();

  useEffect(() => {
    if (accessToken) {
      setAuthorization(accessToken);
    }
  }, [accessToken]);

  const errorLink = useErrorLink();

  const uri = config.datagonUrl;

  const client = useMemo(() => {
    const httpLink = new HttpLink({ uri });

    const link = authLink.concat(
      ApolloLink.from([errorLink, sentryLink, httpLink]),
    );

    return new ApolloClient({
      cache: new InMemoryCache(),
      connectToDevTools: true,
      link,
    });
  }, [errorLink, uri]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

GraphQLProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

GraphQLProvider.displayName = 'GraphQLProvider';

export default GraphQLProvider;
