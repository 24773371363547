import { Alert, AlertProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useFormState } from 'react-final-form';

const displayName = 'SubmitErrorAlert';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: displayName },
);

const SubmitErrorAlert: React.FC<AlertProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const state = useFormState();
  const error = state.submitError as string;

  if (error) {
    return (
      <Alert
        className={clsx(classes.root, className)}
        severity="error"
        {...rest}
      >
        {error}
      </Alert>
    );
  }

  return null;
};

SubmitErrorAlert.displayName = displayName;

export default SubmitErrorAlert;
