import type { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import paths from '../../paths.json';

const useErrorLink = (): ApolloLink => {
  const navigate = useNavigate();

  return useMemo(
    () =>
      onError((errorResponse) => {
        const { graphQLErrors, operation } = errorResponse;
        if (graphQLErrors) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          graphQLErrors.map(({ message, type, ...error }) => {
            if (type === 'AccessDeniedException') {
              navigate(paths.login, {
                replace: true,
                state: {
                  error: 'Session expired. Please login again.',
                },
              });

              return;
            }

            Sentry.withScope((scope) => {
              scope.setExtras({
                ...error,
                query: operation.query.loc?.source.body,
                variables: JSON.stringify(operation.variables, null, '  '),
              });

              Sentry.captureException(new Error(message));
            });
          });
        }
      }),
    [navigate],
  );
};

export default useErrorLink;
