import { isApolloError } from '@apollo/client';
import { Box, Link, makeStyles } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { FORM_ERROR } from 'final-form';
import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useAuthentication } from '../../components/AuthenticationProvider';
import HeadTitle from '../../components/HeadTitle';
import SubmitErrorAlert from '../../components/SubmitErrorAlert';
import Welcome from '../../components/Welcome';
import FormField from '../../components/Welcome/FormField';
import AuthException, {
  AuthExceptionMessage,
} from '../../constants/AuthException';
import paths from '../../paths.json';
import { required } from '../../validate';

const displayName = 'ResetPassword';

const useStyles = makeStyles(
  (theme) => ({
    accountText: {
      textAlign: 'center',
    },

    alert: {
      marginBottom: theme.spacing(2),
    },

    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },

    reset: {
      margin: 0,
      textAlign: 'center',
    },

    submit: {
      marginTop: theme.spacing(1.5),
    },
  }),
  { name: displayName },
);

const ResetPassword: FC = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { forgotPassword } = useAuthentication();

  const onSubmit = useCallback(
    async ({
      email,
    }: {
      email: string;
    }): Promise<Record<string, string> | undefined> => {
      try {
        await forgotPassword(email);
      } catch (e) {
        let message = 'Critical error during password reset. Please try again.';
        if (isApolloError(e)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const type = e.graphQLErrors?.[0]?.type as AuthException | undefined;
          message = (type && AuthExceptionMessage[type]) || e.message;
        }

        return {
          [FORM_ERROR]: message,
        };
      }

      navigate(paths.login, {
        state: {
          success:
            'You will receive an email with instructions about how to reset your password in a few minutes.',
        },
      });

      return undefined;
    },
    [forgotPassword, navigate],
  );

  return (
    <>
      <HeadTitle>Reset Password</HeadTitle>
      <Welcome>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <SubmitErrorAlert />
              <FormField
                label="Email"
                name="email"
                type="email"
                validate={required}
              />
              <LoadingButton
                className={classes.submit}
                color="primary"
                fullWidth
                pending={submitting}
                type="submit"
                variant="contained"
              >
                Send Reset Instructions
              </LoadingButton>
            </form>
          )}
        </Form>
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Link component={RouterLink} to={paths.login}>
            Back to Login?
          </Link>
        </Box>
      </Welcome>
    </>
  );
};

ResetPassword.displayName = displayName;

export default ResetPassword;
