enum AuthException {
  AuthException = 'AuthException',
  AuthInvalidPasswordException = 'AuthInvalidPasswordException',
  AuthUsernameExistsException = 'AuthUsernameExistsException',
  AuthUserNotFoundException = 'AuthUserNotFoundException',
  AuthLoginFailedException = 'AuthLoginFailedException',
}

export const AuthExceptionMessage = {
  [AuthException.AuthException]:
    'There was an error during login. Please try again.',
  [AuthException.AuthInvalidPasswordException]:
    'The password restrictions are not met.',
  [AuthException.AuthUsernameExistsException]: 'The user already exists.',
  [AuthException.AuthUserNotFoundException]:
    'The username or password is wrong.',
  [AuthException.AuthLoginFailedException]:
    'The username or password is wrong.',
};

export default AuthException;
