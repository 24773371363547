import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

type HeadTitleProps = {
  children: string;
};

const HeadTitle = ({ children }: HeadTitleProps): JSX.Element => (
  <Helmet>
    <title>{children}</title>
  </Helmet>
);

HeadTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
